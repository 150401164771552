<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.files") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid grid-cols-3 gap-4">
      <div
        class="card p-4"
        :class="
          isCreatingFolder || isCreatingFile || isEditingFolder
            ? 'col-span-3 lg:col-span-2'
            : 'col-span-3'
        "
      >
        <div class="p-4">
          <div class="flex items-center gap-2 pb-4">
            <div class="cursor-pointer" @click="setCurrentFolder()">
              {{ t("headings.folders") }}
            </div>
            <div
              v-for="(folder, index) of currentPath"
              class="flex items-center"
            >
              <ChevronRightIcon class="h-4 w-4" />
              <div class="cursor-pointer pl-2" @click="jumpToFolder(index)">
                {{ folder }}
              </div>
            </div>
          </div>
          <h2>{{ t("headings.folders") }}</h2>
          <div
            v-if="!currentFolder.folders || !currentFolder.folders.length"
            class="py-8 text-center"
          >
            {{ t("labels.no_folders") }}
          </div>
          <div
            v-else
            class="grid grid-cols-2 gap-4 py-4 lg:grid-cols-3 xl:grid-cols-4"
          >
            <div
              v-for="(folder, folderIndex) in currentFolder.folders"
              :key="folderIndex"
              class="cursor-pointer rounded-md border border-slate-300 p-4"
              :style="{
                borderColor: folderHovers[folderIndex]
                  ? company.primary[500]
                  : null,
              }"
              @click="setCurrentFolder(folder)"
              @mouseover="folderHovers[folderIndex] = true"
              @mouseleave="folderHovers[folderIndex] = false"
            >
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <FolderIcon
                    class="h-6 w-6"
                    :style="{
                      color: company.primary[500],
                    }"
                  />
                  <div class="font-bold">{{ folder.name }}</div>
                </div>
                <div
                  class="rounded-md border border-slate-200 p-2 hover:border-slate-300"
                  @click.stop="
                    () => {
                      cancelFile();
                      cancelFolder();
                      startEditing(folder);
                    }
                  "
                >
                  <PencilIcon class="h-5 w-5" />
                </div>
              </div>
              <div class="py-2">
                {{ t("labels.estates") }}
                <ul class="my-0 py-0">
                  <li v-for="estateId in folder.estateIds" :key="estateId">
                    {{ getEstateName(estateId, estates) }}
                  </li>
                </ul>
              </div>
              <div class="py-2">
                {{ t("labels.roles") }}
                <ul class="my-0 py-0">
                  <li v-for="roleId in folder.roleIds" :key="roleId">
                    {{ t(`roles.${getRoleName(roleId, roles)}`) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="border-slate-200 py-4" />
          <h2>{{ t("headings.files") }}</h2>
          <div
            v-if="!currentFolder.files || !currentFolder.files.length"
            class="py-8 text-center"
          >
            {{ t("labels.no_files") }}
          </div>
          <div
            v-else
            class="grid grid-cols-2 gap-4 py-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6"
          >
            <div
              v-for="(file, fileIndex) in currentFolder.files"
              :key="fileIndex"
              class="cursor-pointer rounded-md border border-slate-300 p-4"
              :style="{
                borderColor: fileHovers[fileIndex]
                  ? company.primary[500]
                  : null,
              }"
              @click="openFile(file.path)"
              @mouseover="fileHovers[fileIndex] = true"
              @mouseleave="fileHovers[fileIndex] = false"
            >
              <DocumentIcon
                :style="{
                  color: company.primary[500],
                }"
              />
              <div class="font-bold">{{ file.name }}</div>
            </div>
          </div>
        </div>
        <hr class="border-slate-200 pb-4" />
        <div class="flex justify-end gap-2">
          <form-button
            :command="
              () => {
                cancelFile();
                cancelFolder();
                isCreatingFolder = true;
              }
            "
            :icon="PlusIcon"
            id="create-folder-button"
            label="create_folder"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
          <form-button
            :command="
              () => {
                cancelFile();
                cancelFolder();
                isCreatingFile = true;
              }
            "
            :icon="DocumentArrowUpIcon"
            id="upload-file-button"
            label="upload_file"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
      <div
        v-if="isCreatingFolder || isEditingFolder"
        class="card col-span-3 flex flex-col p-4 lg:col-span-1"
      >
        <div class="flex items-center justify-between pb-8">
          <h3>
            {{
              isCreatingFolder
                ? t("headings.create_folder")
                : t("headings.edit_folder")
            }}
          </h3>
          <XMarkIcon class="h-6 w-6 cursor-pointer" @click="cancelFolder()" />
        </div>
        <form
          @submit.prevent="isCreatingFolder ? createFolder() : editFolder()"
          class="flex w-full flex-grow flex-col justify-between"
        >
          <div class="flex flex-col gap-4">
            <div>
              <label>{{ t("labels.name") }}</label>
              <form-input
                class="w-full"
                id="folder-name-input"
                :invalid="folderState.name.error ? true : false"
                type="text"
                v-model="folderState.name.value"
              />
              <small v-if="folderState.name.error" class="text-rose-600">{{
                t(`form.errors.${folderState.name.error}`)
              }}</small>
            </div>
            <div>
              <label>{{ t("labels.estates") }}</label>
              <form-multiselect
                class="w-full"
                id="folder-estate-select"
                :invalid="folderState.estateIds.error ? true : false"
                :optionLabel="['name']"
                :options="estates"
                v-model="folderState.estateIds.value"
              />
              <small v-if="folderState.estateIds.error" class="text-rose-600">{{
                t(`form.errors.${folderState.estateIds.error}`)
              }}</small>
            </div>
            <div>
              <label>{{ t("labels.roles") }}</label>
              <form-multiselect
                class="w-full"
                id="folder-roles-select"
                :invalid="folderState.roleIds.error ? true : false"
                :optionLabel="['label']"
                :options="
                  roles.filter(
                    (role) =>
                      role.label == 'shareholder' ||
                      role.label == 'board_member'
                  )
                "
                translatableLabel="roles"
                v-model="folderState.roleIds.value"
              />
              <small v-if="folderState.roleIds.error" class="text-rose-600">{{
                t(`form.errors.${folderState.roleIds.error}`)
              }}</small>
            </div>
          </div>
          <div>
            <hr class="border-slate-200 pb-4" />
            <div class="flex justify-end">
              <form-button
                :disabled="waiting"
                id="save-folder-button"
                label="save"
                :textVariant="company.primaryText"
                type="submit"
                :variant="company.primary"
              />
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="isCreatingFile"
        class="card col-span-3 flex flex-col p-4 lg:col-span-1"
      >
        <div class="flex items-center justify-between pb-8">
          <h3>{{ t("headings.create_file") }}</h3>
          <XMarkIcon class="h-6 w-6 cursor-pointer" @click="cancelFile()" />
        </div>
        <form
          @submit.prevent="createFile()"
          class="flex w-full flex-grow flex-col justify-between gap-4"
        >
          <div class="flex flex-col gap-4">
            <div>
              <form-file-dropper
                accept=""
                :disabled="waiting"
                id="filedropper"
                :multiple="false"
                v-model="fileState.file.value"
                :showFilename="true"
                :textVariant="company.primaryText"
                :variant="company.primary"
              />
              <small v-if="fileState.file.error" class="text-rose-600">{{
                t(`form.errors.${folderState.file.error}`)
              }}</small>
            </div>
          </div>
          <div>
            <hr class="border-slate-200 pb-4" />
            <div class="flex justify-end">
              <form-button
                :disabled="waiting"
                id="save-file-button"
                label="save"
                :textVariant="company.primaryText"
                type="submit"
                :variant="company.primary"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronRightIcon,
  DocumentArrowUpIcon,
  DocumentIcon,
  FolderIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormFileDropper from "../components/form/FormFileDropper.vue";
import FormInput from "../components/form/FormInput.vue";
import FormMultiselect from "../components/form/FormMultiselect.vue";
import FormSelect from "../components/form/FormSelect.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getEstateName } from "../utils/estateUtil";
import { getRoleName } from "../utils/roleUtils";

export default {
  components: {
    Breadcrumb,
    ChevronRightIcon,
    DocumentArrowUpIcon,
    DocumentIcon,
    FolderIcon,
    FormButton,
    FormFileDropper,
    FormInput,
    FormMultiselect,
    FormSelect,
    PencilIcon,
    PlusIcon,
    XMarkIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const fileTree = computed(() => store.state.file.fileTree);
    const file = computed(() => store.state.file.file);
    const roles = computed(() => store.state.role.roles);
    const status = computed(() => store.state.file.status);
    const user = computed(() => store.state.authentication.user);
    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.file.waiting) return true;
      if (store.state.role.waiting) return true;
      return false;
    });

    const isCreatingFolder = ref(false);
    const isEditingFolder = ref(false);
    const folderState = reactive({
      name: {
        value: "",
        error: null,
      },
      id: {
        value: "",
      },
      estateIds: {
        value: [],
      },
      roleIds: {
        value: [],
      },
    });

    const createFolder = () => {
      if (!folderState.name.value.length) {
        folderState.name.error = "isRequired";
      } else if (
        currentFolder.value.folders &&
        currentFolder.value.folders.find(
          (folder) => folder.name === folderState.name.value
        )
      ) {
        folderState.name.error = "isUnique";
      } else {
        folderState.name.error = null;
        store.dispatch("file/createFolder", {
          companyId: company.value.id,
          path: currentPath.value,
          name: folderState.name.value,
          estateIds: folderState.estateIds.value,
          roleIds: folderState.roleIds.value,
        });
      }
    };

    const startEditing = (folder) => {
      folderState.name.value = folder.name;
      folderState.id.value = folder.id;
      folderState.estateIds.value = folder.estateIds;
      folderState.roleIds.value = folder.roleIds;
      isEditingFolder.value = true;
    };

    const editFolder = () => {
      if (!folderState.name.value.length) {
        folderState.name.error = "isRequired";
      } else {
        folderState.name.error = null;
        store.dispatch("file/updateFolder", {
          companyId: company.value.id,
          path: currentPath.value,
          id: folderState.id.value,
          name: folderState.name.value,
          estateIds: folderState.estateIds.value,
          roleIds: folderState.roleIds.value,
        });
      }
    };

    const cancelFolder = () => {
      isCreatingFolder.value = false;
      isEditingFolder.value = false;
      folderState.name = {
        value: "",
        error: null,
      };
      folderState.permission = {
        value: null,
      };
      folderState.estateIds = {
        value: [],
      };
      folderState.roleIds = {
        value: [],
      };
    };

    const isCreatingFile = ref(false);
    const fileState = reactive({
      file: {
        value: null,
        error: null,
      },
    });

    const createFile = () => {
      if (!fileState.file.value) {
        fileState.file.error = "isRequired";
      } else {
        fileState.file.error = null;
        store.dispatch("file/createFile", {
          companyId: company.value.id,
          path: currentPath.value,
          file: fileState.file.value,
        });
      }
    };

    const cancelFile = () => {
      isCreatingFile.value = false;
      fileState.file = {
        value: "",
        error: null,
      };
    };

    const folderHovers = ref([]);
    const fileHovers = ref([]);
    const currentPath = ref([]);
    const currentFolder = ref(fileTree.value);

    const setCurrentFolder = (folder) => {
      if (!folder) {
        currentPath.value = [];
        currentFolder.value = fileTree.value;
      } else if (folder === currentFolder.value) {
        let folderPath = fileTree.value;
        for (const folderName of currentPath.value) {
          if (folderPath.folders) {
            folderPath = folderPath.folders.find(
              (folder) => folder.name === folderName
            );
          }
        }
        currentFolder.value = folderPath;
      } else {
        currentPath.value.push(folder.name);
        currentFolder.value = folder;
      }
    };

    const jumpToFolder = (index) => {
      currentPath.value.splice(index + 1, currentPath.value.length - index);
      let folderPath = fileTree.value;
      for (const folderName of currentPath.value) {
        if (folderPath.folders) {
          folderPath = folderPath.folders.find(
            (folder) => folder.name === folderName
          );
        }
      }
      currentFolder.value = folderPath;
    };

    const openFile = (path) => {
      store.dispatch("file/getFile", path);
    };

    onMounted(() => {
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", user.value.companyId);
      }
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
      if (!fileTree.value.id) {
        store.dispatch("file/getFileTree", user.value.companyId);
      } else {
        setCurrentFolder();
      }
    });

    watch(
      () => file.value,
      (file) => {
        if (file) {
          window.open(file, "_blank");
        }
      }
    );

    watch(
      () => fileTree.value,
      (fileTree) => {
        if (fileTree.id) {
          setCurrentFolder(
            currentFolder.value.name ? currentFolder.value : null
          );
        }
      }
    );

    watch(
      () => status.value,
      () => {
        cancelFile();
        cancelFolder();
      }
    );

    return {
      cancelFile,
      cancelFolder,
      ChevronRightIcon,
      company,
      createFile,
      createFolder,
      currentFolder,
      currentPath,
      DocumentArrowUpIcon,
      DocumentIcon,
      editFolder,
      estates,
      fileHovers,
      fileTree,
      fileState,
      folderHovers,
      folderState,
      getEstateName,
      getLocaleDate,
      getRoleName,
      isCreatingFile,
      isCreatingFolder,
      isEditingFolder,
      jumpToFolder,
      locale,
      openFile,
      PencilIcon,
      PlusIcon,
      roles,
      router,
      setCurrentFolder,
      startEditing,
      t,
      waiting,
    };
  },
};
</script>
