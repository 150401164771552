<template>
  <div>
    <div
      class="flex h-64 items-center justify-center rounded border-2 border-dashed border-gray-300 bg-gray-100"
      @drop.prevent="onUpload"
      @dragenter.prevent
      @dragover.prevent
    >
      <div class="flex items-center gap-4">
        <h4 class="text-lg font-semibold">{{ t("text.drag") }}</h4>
      </div>
    </div>
    <div class="pt-5">
      <input
        class="hidden"
        :accept="accept"
        :id="id"
        :multiple="multiple"
        type="file"
        @change="onUpload"
      />
      <form-button
        :command="() => openFileDialog()"
        :disabled="disabled"
        :id="`${id}-button`"
        :label="label"
        :textVariant="textVariant"
        type="button"
        :variant="variant"
      />
    </div>
    <div v-if="filename && showFilename" class="mt-2 flex items-center gap-1">
      <CheckCircleIcon class="h-6 w-6 text-green-600" />{{ filename }}
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import FormButton from "./FormButton.vue";

export default {
  components: { CheckCircleIcon, FormButton },
  props: {
    accept: {
      required: true,
      type: String,
    },
    disabled: {
      required: true,
      type: Boolean,
    },
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    label: {
      default: "choose_file",
      type: String,
    },
    modelValue: {},
    multiple: {
      default: false,
      type: Boolean,
    },
    showFilename: {
      default: true,
      type: Boolean,
    },
    textVariant: {
      default: "light",
      type: String,
    },
    variant: {
      default: "indigo",
      type: [Object, String],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const openFileDialog = () => {
      document.getElementById(props.id).click();
    };

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const filename = ref(props.modelValue?.name);

    const onUpload = (event) => {
      let file = null;
      if (event.target.files) {
        file = event.target.files[0];
      } else if (event.dataTransfer.items) {
        const item = event.dataTransfer.items[0];
        if (item.kind === "file") {
          file = item.getAsFile();
        }
      } else {
        file = event.dataTransfer.files[0];
      }
      filename.value = file.name;
      getBase64(file).then((data) => {
        const fileData = {
          name: filename.value,
          file: data,
        };
        emit("update:modelValue", fileData);
      });
    };

    return {
      filename,
      onUpload,
      openFileDialog,
      t,
    };
  },
};
</script>
